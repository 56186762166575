<template>
  <div>
    <b-form>
      <form-field 
        v-for="(field, name) in form.fields" 
        :form="form" 
        :key="name" 
        :name="name" 
        @input="onInput"
        @create="onCreate"
      />

      <b-button class="mr-3" @click="$emit('cancel')">Annuleer</b-button>
      <b-button variant="primary">Opslaan</b-button>
    </b-form>

    <b-modal size="xl" id="create-group" title="Create Rule Group" hide-footer>
      <trx-rule-group-edit />
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "TrxMatchEdit",
  components: {
    'form-field': () => import('@/components/FormFieldV2'),
    'trx-rule-group-edit': () => import('@/components/TrxRuleGroupEdit'),
  },
  data() {
    return {
      form: {
        values: {},
        fields: {
          FirstAccountingPeriodID: {
            label: 'First Period',
            type: 'text',
          },
          LastAccountingPeriodID: {
            label: 'Last Period',
            type: 'text',
          },
          TransactionType: {
            label: 'Transaction Type',
            type: 'select',
            options: [ 
              { value: 'all', text: 'All' },
              { value: 1, text: 'Audio Download' },
              { value: 2, text: 'Audio Streaming' },
              { value: 3, text: 'Streaming' },
              { value: 4, text: 'Track Download' },
              { value: 5, text: 'Album Download' },
              { value: 6, text: 'ContentID' },
            ]
          },
          Group: {
            label: 'Group',
            type: 'select',
            options: [],
            create: true
          },
        },
      },
    };
  },
  props: {
  },
  methods: {
    onInput (name, value) {
      const def = this.form.fields[name]
      console.log(value, name, def)

      if (value == '<create>' && def.create) {
        console.log('Create group')
        this.$bvModal.show('create-group')
        return
      }
    },
    onCreate (name) {
      console.log(name)
    },
  },
  watch: {
  },
  computed: {
    data: {
      get () {
        return ''
      },
      set () {

      }
    },
  },
  mounted() {
    this.$http.get('accounting/rule-groups', {
          params: { perPage: null }
        })
        .then(response => {
          let options = response.data.map(item => { 
            return { 
              value: item.TransactionReaccountRuleGroupID,
              text: item.Description,
            } 
          })

          options.unshift(
            { value: '<create>', text: '<create new group>' }
          )

          this.form.fields.Group.options = options
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            text: 'Er ging iets mis bij het laden'
          });
        });
  }
};

</script>
